import { toastr } from 'react-redux-toastr';
import history from '../../../app/history';
import { api } from '../../../api/api';
import { setMenuList } from '../menu/menuAction';
import {
  setFacilitySearch,
  setFacilitySearchList,
} from '../search/searchAction';
import { setFacilityList } from '../facility/faciliyAction';
import { setExtrasList } from '../extras/extrasAction';
import { setGuestDetailsList } from '../guestDetails/guestDetailsAction';
import { setBookingList } from '../booking/bookingAction';
import { setBookingAccessList } from '../bookingAccess/bookingAccessAction';

export const searchFacilityApi = (data) => {
  return async (dispatch) => {
    dispatch(
      setFacilitySearch(data.category, data.start, data.end, data.person)
    );
    if (data.category === 'all') {
      data.category = '';
    }
    let dataToSend = {
      category: data.category,
      arrival: data.start,
      departure: data.end,
      person: data.person,
    };
    let response = await api(
      `/facilities-search?page=1&limit=999999`,
      dataToSend,
      'post'
    );

    if (response && response.status === 200) {
      dispatch(
        setFacilitySearchList(
          response.data.data,
          response.data.page,
          response.data.pageLimit,
          response.data.totalFacilities
        )
      );
      history.push('/facility-search');
    } else {
      toastr.error('Facility Not Searched');
    }
  };
};
export const menuApi = () => {
  return async (dispatch) => {
    let response = await api(`/menu`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(
        setMenuList(
          response.data.data,
          response.data.page,
          response.data.pageLimit,
          response.data.totalCount
        )
      );
    }
  };
};
export const menuBySlug = async (slug) => {
  let response = await api(`/menu/${slug}`, {}, 'get');
  return response;
};

export const facilityListApi = (data) => {
  return async (dispatch) => {
    let response = await api(`/facilities?page=1&limit=999999`, data, 'post');
    if (response && response.status === 200) {
      dispatch(
        setFacilityList(
          response.data.data,
          response.data.page,
          response.data.pageLimit,
          response.data.sort
        )
      );
      // history.push("/facility-search");
    } else {
      toastr.error('Facility Not Searched');
    }
    return response;
  };
};
export const facilityIDApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/facility/${id}`, {}, 'get');

    return response;
  };
};

export const ExtrasApi = (type = '') => {
  return async (dispatch) => {
    let response = await api(`/extras?type=${type}`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(
        setExtrasList(
          response.data.data,
          response.data.page,
          response.data.pageLimit,
          response.data.totalCount
        )
      );
    }
    return response;
  };
};
export const CartApi = async () => {
  let response = await api(`/cart/get-cart`, {}, 'get');

  return response;
};
export const UpdateCartApi = async (data) => {
  let response = await api(`/cart/update`, data, 'patch');
  return response;
};
export const CheckAvailability = async (data) => {
  let response = await api(`/cart/check-availability`, data, 'post');
  return response;
};
export const CheckMarinaAvailability = async (data) => {
  let response = await api(`/marina/check-availability`, data, 'post');
  return response;
};
export const CheckMarinaBulkAvailability = async (data) => {
  console.log(data);
  let response = await api(`/marina/check-all-availability`, data, 'post');
  return response;
};
export const BookingApi = async (id) => {
  let response = await api(`/booking/${id}`, {}, 'post');
  return response;
};
export const ContactApi = async (value) => {
  let data = {
    name: value.name,
    email: value.email,
    mobile: value.mobile,
    enquiryType: value.enquiry,
    message: value.message,
  };
  let response = await api('/contact-us', data, 'post');
  if (response && response.status === 201) {
    toastr.success('Form submitted successfully');
  } else {
    toastr.error('Form not submitted');
  }
};
export const updateGuestDetailsApi = async (data) => {
  let response = await api(`/guest`, data, 'patch');
  return response;
};

export const updateGuestAddressApi = async (data) => {
  let response = await api(`/guest-address`, data, 'patch');
  return response;
};

export const resetPasswordGuestApi = async (data) => {
  let response = await api(`/change-password`, data, 'patch');
  return response;
};
export const guestDetailsApi = () => {
  return async (dispatch) => {
    let response = await api(`/guest`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(
        setGuestDetailsList(
          response.data.data,
          response.data.page,
          response.data.pageLimit,
          response.data.totalCount
        )
      );
    }
    return response;
  };
};

export const guestBookingsDetailsApi = (page, limit) => {
  return async (dispatch) => {
    let response = await api(
      `/bookings?page=${page}&limit=${limit}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setBookingList(
          response.data.data,
          response.data.page,
          response.data.pageLimit,
          response.data.totalCount
        )
      );
    }
    return response;
  };
};

export const bookingAccessApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/booking/${id}`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(
        setBookingAccessList(
          response.data.data,
          response.data.page,
          response.data.pageLimit,
          response.data.totalCount
        )
      );
    }
    return response;
  };
};
export const reviewApi = async (data) => {
  let response = await api(`/review`, data, 'post');
  return response;
};

export const MonerisTicketApi = async (data) => {
  let response = await api(`/moneris-ticket`, data, 'post');
  return response;
};

export const createTransaction = async (data) => {
  let response = await api(`/transactions`, data, 'post');
  return response;
};

export const updateTransaction = async (data, id) => {
  let response = await api(`/transactions/${id}`, data, 'patch');
  return response;
};

export const getOneTransaction = async (id) => {
  let response = await api(`/transactions/${id}`, {}, 'get');
  return response;
};

export const checkCampsiteApi = async (data) => {
  let response = await api(`/cart/check-campsite`, data, 'post');
  return response;
};
