import { store } from '../redux/storeConfig/store';

import history from '../app/history';

const axios = require('axios');

// const mainUrl = 'http://localhost:1337';
const mainUrl = 'https://stage.pitkabay.com/api';

/**
 *
 * @param {apiEndpoint for auth} endpoint
 * @param {value} data
 * @param {get,post,patch,delete,put} type
 */
export const auth = async (endpoint, data, type) => {
  var res;
  var token = store.getState().auth.values.loggedInUser
    ? store.getState().auth.values.loggedInUser.token
    : '';
  switch (type) {
    case 'post':
      await axios({
        data: data,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': token,
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          // console.log(err);
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem('user');
            history.push('/login');
          } else {
            res = err.response;
          }
        });
      break;
    case 'get':
      await axios({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': token,
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem('user');
            history.push('/login');
          } else {
            res = err.response;
          }
        });
      break;
    case 'put':
      await axios({
        method: 'put',
        data: data,
        headers: {
          'Content-Type': 'application/json',
          'x-auth': token,
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem('user');
            history.push('/login');
          } else {
            res = err.response;
          }
        });
      break;
    case 'delete':
      await axios({
        data: data,
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': token,
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem('user');
            history.push('/login');
          } else {
            res = err.response;
          }
        });
      break;
    case 'postWithoutToken':
      await axios({
        method: 'post',
        data: data,
        headers: {
          'Content-Type': 'application/json',
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          res = err.response;
        });
      break;

    default:
      return true;
  }
  return res;
};

export const checkData = (data) => {
  return true;
};
