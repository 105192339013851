import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import Spinner from './components/common/spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bulma/css/bulma.min.css';
import './assets/css/style.scss';
import 'flatpickr/dist/themes/material_green.css';
import './assets/css/slick/slick.scss';
import './assets/css/slick/slick-theme.scss';
import './components/facility/facilityList/facilityList.scss';
import './components/common/facilitySearch/facilitySearch.scss';
import * as serviceWorker from './serviceWorker';
import { store } from './redux/storeConfig/store';
import { auth } from './api/auth';
import history from './app/history';
import { CartApi } from './redux/actions/apiAction/apiAction';
import { setCartCount } from './redux/actions/cart/cartAction';

const LazyApp = lazy(() => import('./app/AppRouter'));

const jsx = (
  <Provider store={store}>
    <Suspense fallback={Spinner}>
      <GoogleOAuthProvider clientId="943661539604-5mj40mjc61flvqbp2hdksonjsbg8o3dq.apps.googleusercontent.com">
        <LazyApp />
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </GoogleOAuthProvider>
    </Suspense>
  </Provider>
);
ReactDOM.render(jsx, document.getElementById('root'));

// let hasRendered = false;

const renderApp = (user = '') => {
  ReactDOM.render(jsx, document.getElementById('root'));
};

serviceWorker.register();

const renderLogin = () => {
  renderApp();
};
const setCartData = async () => {
  let response = await CartApi();
  if (response && response.status === 200) {
    if (response.data.data.facilityLine || response.data.data.marinaLines) {
      localStorage.setItem('cartPitka', JSON.stringify(response.data.data));
      store.dispatch(
        setCartCount(
          response.data.data.facilityLine.length +
            response.data.data?.marinaLines?.length
        )
      );
    } else {
      localStorage.setItem(
        'cartPitka',
        JSON.stringify({
          arrival: new Date().setHours(14, 0, 0, 0),
          departure: new Date().setHours(34, 0, 0, 0),
          facilityLine: [],
          marinaLines: [],
          totalAmount: 0,
          totalGstAmount: 0,
          totalPstAmount: 0,
        })
      );
    }
  }
};
const tokenCheck = async () => {
  let response = await auth(`/check-guest-token`, {}, 'get');

  if (response && response.status !== 200) {
    store.dispatch({
      type: 'LOGIN_WITH_JWT',
      payload: {},
    });
    store.dispatch({
      type: 'SET_LOGIN_FLAG',
      payload: { data: false },
    });

    localStorage.removeItem('clientPitka');

    history.push('/');
    return response;
  }
};
try {
  // console.log("in index.js file");

  let user = localStorage.getItem('clientPitka');
  // store.dispatch(getLoginBackground());

  // Set inital dates & other data
  let today = new Date();
  let nextDate = new Date();
  nextDate = nextDate.setDate(today.getDate() + 1);
  let currentData = {
    arrival: today.setHours(14, 0, 0, 0),
    departure: new Date(nextDate).setHours(10, 0, 0, 0),
    category: 'all',
    person: 1,
  };
  localStorage.setItem('data', JSON.stringify(currentData));
  // localStorage.removeItem("cartPitka");
  if (user) {
    user = JSON.parse(user);
    store.dispatch({
      type: 'LOGIN_WITH_JWT',
      payload: { loggedInUser: user, loggedInWith: 'jwt' },
    });
    store.dispatch({
      type: 'SET_LOGIN_FLAG',
      payload: { data: true },
    });
    let result = tokenCheck();
    if (result && result.status === 200) {
      setCartData();
    }
  }
  renderLogin();
} catch (e) {
  renderLogin();
}
